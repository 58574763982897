import React, { FunctionComponent } from "react"
import { PageProps } from "gatsby"

interface OwnProps {}

type Props = PageProps<OwnProps>

const HomePage: FunctionComponent<Props> = props => {
  return <pre>404 Not Found</pre>
}

export default HomePage
